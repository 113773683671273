.features-table {
    position: relative;
    width: 100%;
}

.features-table .button {
    display: "block"; 
    max-width: 200;
    margin: "auto";
    margin-left: 20px;
}
.features-table .button:nth-child(1) {
    margin-left: 0;
}

@include media( '<medium' ) {
    .features-table th {
        max-width: 100px;
        padding: 8px 3px;
    }

    .features-table td:nth-child(1) {
        max-width: 90px;
        font-size: 13px;
    }

    .features-table .button {
        margin-left: 0;
        margin-top: 20px;
    }
    .features-table .button:nth-child(1) {
        margin-top: 0;
    }

    .features-table.features-links h4 {
        font-size: 15px;
    }
}